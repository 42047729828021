import { createApp } from 'vue';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import App from './App.vue';
import router from './router';
import store from './store';


UIkit.use(Icons);

const app = createApp(App);

app.config.globalProperties.$uikit = UIkit;

app.use(store).use(router);

app.mount('#app');
