<template>
    <Search v-if="userStatus" />
    <Sidebar v-if="userStatus" />

    <div class="uk-container uk-container-expand border-btm" uk-sticky="start: 20vh;">
        <Navbar v-if="userStatus" />
    </div>

    <div class="uk-margin-medium-bottom">
        <router-view />
    </div>
</template>

<script>
    import Navbar from '@/dashboard/navbar/Navbar.vue';
    import Sidebar from '@/dashboard/sidebar/Sidebar.vue';
    import Search from '@/components/search/Search.vue';

    export default {
      name: 'App',
      components: {
          Navbar,
          Sidebar,
          Search,
      },
        data() {
            return {
                userStatus: false
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.status.loggedIn;
            },
        },
        created() {
            if (this.loggedIn) {
                this.userStatus = true;
            }
        },
    };
</script>

<style lang="scss">
  // 1. Your custom variables and variable overwrites.
  $navbar-background: #fff;
  $white-contrast: #dee2e6;
  $global-medium-box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  //$background-green: $global-success-background;
  $icon-path: '../node_modules/uikit/src/images/icons/';
  $alert-danger-background: #f0506e;
  $alert-danger-color: $navbar-background;

  // 2. Import default variables and available mixins.
  @import "../node_modules/uikit/src/scss/variables-theme.scss";
  @import "../node_modules/uikit/src/scss/mixins-theme.scss";

  // 3. Your custom mixin overwrites.
  @mixin hook-form-icon() {
      &.uk-form-icon-flip.has-datalist {
          right: 20px;

          & ~ .uk-input {
              padding-right: 60px !important;
          }
      }
  }
  @mixin hook-card() {
    border-radius: $border-rounded-border-radius;
    border: 1px solid $white-contrast;
    background-color: #fff;
    //overflow: hidden;

      &.card-hasfooter {
          padding-bottom: 66px;
      }
  }
  @mixin hook-card-header() {
    border-bottom: 1px solid $white-contrast;
  }
  @mixin hook-card-body() {
    overflow-x: hidden;
    overflow-y: scroll;
      max-height: 650px;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bdc1c8;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $global-primary-background;
    }

  }
  @mixin hook-card-footer() {
    border-top: 1px solid $white-contrast;
  }
  @mixin hook-alert-close(){
    color: $navbar-background;
  }
  @mixin hook-alert() {
    border-radius: $border-rounded-border-radius;
    box-shadow: $global-large-box-shadow;
  }
  @mixin hook-button-text() {
    .uk-icon {
        vertical-align: text-top;
    }
  }
  @mixin hook-button-text-hover() {
    border-bottom: none;
    color: #666;
  }
  @mixin hook-navbar-toggle-icon-hover() {
    cursor: pointer;
  }
  @mixin hook-modal-header() {
      padding: 10px 40px 10px 20px;
  }
  @mixin hook-sticky-misc() {
      .uk-sticky-fixed {
          background-color: $navbar-background;
      }
  }

  // 4. Import UIkit.
  @import "../node_modules/uikit/src/scss/uikit-theme.scss";

  // 5. Own SCSS Styles

  @import "@/assets/css/owfont-regular.css";

  $color-map: (
          red: $global-danger-background,
          green: $global-success-background,
          blue: $global-primary-background,
          orange: $global-warning-background,
          coral: #F08080,
          pink: #FFC0CB,
          khaki: #F0E68C,
          violet: #EE82EE,
          aquamarine:#66CDAA,
          gray: #D3D3D3,
          igdb: #A66DFF,
          retroplace: #0097A9,
          lunchbox: #FF4900,
  );

  $icon-map: (
          arrow-down: 'arrow-down.svg',
          heart: 'heart.svg',
          info: 'info.svg',
  );

  html, body {

    @each $icon, $file in $icon-map {
      .icon-bg--#{$icon}:after {
        content: '';
        display: block;
        background-image: url('#{$icon-path}#{$file}');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 35px;
        height: 35px;
        opacity: 0.5;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    @each $name, $color in $color-map {
      .background-#{$name} {
        background-color: $color;
        &>* {
          color: #191919;
        }
      }

        .button-#{$name} {
            background-color: $color;
            color: $navbar-background;
            border-color: $color;

            &:hover {
                background-color: darken($color, 3%);
                color: $navbar-background;
                border-color: darken($color, 3%);
            }
        }
    }

    @for $i from 20 through 300 {
      .avatar-#{$i} {
        width: #{$i}px;
      }
    }

      .uk-navbar-nav {
          .uk-nav-divider {
              border-left: 1px solid #aeaeae;
              margin: 25px 0;
          }
      }

    .border-btm {
      border-bottom: 1px solid $white-contrast;
    }

      .uk-button-group {
          .uk-button {
              @media (max-width: 640px) {
                  padding: 0;
              }
          }
      }

    .card-add {
      border: 2px dashed #D3D3D3;
      cursor: pointer;

      button {
        opacity: 0.5;
      }

      &:hover {
        button {
          opacity: 1;
        }
      }
    }

      #markdown-editor {
          border-bottom: 1px solid $white-contrast;

          .preview {
              padding: 20px 0;

              pre {
                  width: 90%;

                  code {
                      font-family: $base-code-font-family;
                      font-size: $base-code-font-size;
                      color: $base-code-color;
                  }
              }
          }
          textarea {
              border: none;
              resize: none;
              outline: none;
              width: 100%;
              padding: 20px;
              font-size: 14px;
              font-family: $base-code-font-family;
          }


          @media (max-width: 640px) {
              .preview, textarea {
                  height: 30vh;
                  aspect: 1/1;
              }
              .preview {
                  padding: 0 20px;
              }
          }
      }

  }
</style>
