import {createRouter, createWebHistory} from 'vue-router';

import Login from '@/components/login/Login.vue';
import Dashboard from '@/pages/dashboard/Dashboard.vue';
import Notes from '@/pages/notes/Notes.vue';
import ToDo from '@/pages/todo/Todo.vue';
import GroceryList from '@/pages/grocerylist/GroceryList.vue';
import Retroplace from '@/pages/retroplace/Retroplace.vue';
import Calendar from '@/pages/calendar/Calendar.vue';


const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/notes',
        name: 'Notes',
        component: Notes,
        meta: {
            title: 'Notizen',
            requiresAuth: true
        }
    },
    {
        path: '/todo',
        name: 'ToDo',
        component: ToDo,
        meta: {
            title: 'ToDo`s',
            requiresAuth: true
        }
    },
    {
        path: '/grocery',
        name: 'GroceryList',
        component: GroceryList,
        meta: {
            title: 'Einkaufszettel',
            requiresAuth: true
        }
    },
    {
        path: '/retroplace',
        name: 'Retroplace',
        component: Retroplace,
        meta: {
            title: 'Retroplace',
            requiresAuth: true
        }
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: {
            title: 'Termine',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !localStorage.hasOwnProperty('user')) {
        next('/login');
    } else if (to.meta.requiresUnauth && !!localStorage.hasOwnProperty('user')) {
        next('/');
    } else {
        next();
    }
});

export default router;