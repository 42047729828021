<template>
    <div>
        <div class="uk-card uk-box-shadow-medium card-hasfooter">
            <div class="uk-card-header">
                <h3 class="uk-card-title">ToDo`s</h3>
            </div>
            <div class="uk-card-body">
                <ul class="uk-list uk-list-divider uk-margin-remove-bottom" uk-sortable="handle: .uk-sortable-handle">
                    <template v-for="todo in content.data" :key="todo">
                        <li v-if="todo.done === 0">
                            <span uk-icon="more-vertical" class="uk-margin-right uk-sortable-handle"></span>
                            <label><input class="uk-checkbox uk-margin-right" type="checkbox" :value="todo.id" @click="doneToDo"> {{ todo.todo }}</label>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="uk-card-footer uk-position-bottom">
                <a href="#modal-todo" class="uk-button uk-button-text" uk-toggle><span uk-icon="icon: plus-circle"></span> Hinzufügen</a>
            </div>
        </div>

        <div id="modal-todo" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body" uk-overflow-auto>
                    <form v-on:submit.prevent="submitToDo">
                        <div class="">
                            <div class="uk-inline uk-width-expand">
                                <a class="uk-form-icon uk-form-icon-flip has-datalist" href="#" @click="submitToDo" uk-icon="icon: plus-circle"></a>
                                <input list="all-todos" class="uk-input" placeholder="z.b. Einkaufen gehen" type="text" v-model="newToDo" autofocus>
                                <datalist id="all-todos">
                                    <option v-for="todo in content.data">{{ todo.todo }}</option>
                                </datalist>
                            </div>
                        </div>
                    </form>

                    <div v-if="newTodos != ''" class="uk-margin-top">
                        <hr class="uk-divider-icon">

                        <ul class="uk-list uk-list-divider uk-margin-remove-bottom">
                            <li v-for="todo in newTodos">{{ todo }}</li>
                        </ul>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-modal-close" type="button" @click="closeModal">Fertig</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TodoAPI from '@/services/api.todo';

    export default {
        name: 'ToDoCard',
        data() {
            return {
                content: '',
                newTodos: []
            };
        },
        methods: {
            doneToDo: function (data) {
                TodoAPI.updateTodo(data.target.value).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            },
            submitToDo: function (data) {
                TodoAPI.postTodo(this.newToDo).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            this.newTodos.push(this.newToDo);
                        }
                    }
                );
            },
            closeModal: function (data) {
                window.location.reload();
            }
        },
        mounted: function() {
            TodoAPI.getAllTodo().then(
                (response) => {
                    this.content = response.data;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>