<template>
    <div>
        <div class="uk-card uk-card-body uk-box-shadow-medium background-green icon-bg--info">
            <h3 class="uk-card-title">Default</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div>
    </div>

    <div>
        <div class="uk-card uk-card-body uk-box-shadow-medium background-blue icon-bg--info">
            <h3 class="uk-card-title">Default</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div>
    </div>

    <div>
        <div class="uk-card uk-card-body uk-box-shadow-medium background-khaki">
            <h3 class="uk-card-title">Default</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>