import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = 'https://api.whydesign-halle.de/api/';

class TodoAPI {
    getAllTodo() {
        return axios.get(API_URL + 'todo',
                { headers: authHeader() }
            );
    }

    updateTodo(id) {
        return axios.put(API_URL + 'todo/' + id,
                { done: 1 },
                { headers: authHeader() }
            );
    }

    postTodo(todo) {
        return axios.post(API_URL + 'todo',
                { todo: todo },
                { headers: authHeader() }
            );
    }
}

export default new TodoAPI();