<template>
    <div>
        <div class="uk-card uk-box-shadow-medium" uk-filter="target: .game-filter">
            <div class="uk-card-header">
                <div>
                    <div class="uk-float-left">
                        <h3 class="uk-card-title uk-margin-remove-bottom">Retroplace Wishlist</h3>
                    </div>

                    <div class="uk-float-right">
                        <nav uk-dropnav>
                            <ul class="uk-subnav">
                                <li>
                                    <a href="#">Filter <span uk-drop-parent-icon></span></a>
                                    <div class="uk-dropdown">
                                        <ul class="uk-nav uk-dropdown-nav">
                                            <li class="uk-active" uk-filter-control><a href="#">Alle</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li uk-filter-control=".360"><a href="#">XBOX 360</a></li>
                                            <li uk-filter-control=".PC"><a href="#">PC</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li uk-filter-control=".NES"><a href="#">NES</a></li>
                                            <li uk-filter-control=".SNES"><a href="#">SNES</a></li>
                                            <li uk-filter-control=".N64"><a href="#">N64</a></li>
                                            <li uk-filter-control=".GB"><a href="#">GB</a></li>
                                            <li uk-filter-control=".GBA"><a href="#">GBA</a></li>
                                            <li uk-filter-control=".GBC"><a href="#">GBC</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li uk-filter-control=".PSX"><a href="#">PSX</a></li>
                                            <li uk-filter-control=".PS2"><a href="#">PS2</a></li>
                                            <li uk-filter-control=".PS3"><a href="#">PS3</a></li>
                                            <li uk-filter-control=".PSP"><a href="#">PSP</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="uk-card-body">
                <table class="uk-table uk-table-small uk-table-divider uk-table-hover uk-table-justify uk-table-responsive">
                    <thead>
                    <tr>
                        <th>Titel</th>
                        <th>Edition</th>
                        <th>System</th>
                    </tr>
                    </thead>
                    <tbody class="game-filter">
                    <template v-for="game in content" :key="game">
                        <tr :class="game.sys">
                            <td>{{ game.title }} <sup class="uk-hidden@m">{{ game.sys }}</sup></td>
                            <td class="uk-visible@m" v-show="game.edition">{{ game.edition }}</td>
                            <td class="uk-visible@m" v-show="game.edition == ''">-</td>
                            <td class="uk-visible@m">{{ game.sys }}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import RetroAPI from '@/services/api.retro';

    export default {
        name: 'Retroplace Wishlist',
        data() {
            return {
                content: '',
            };
        },
        mounted: function() {
            RetroAPI.getWishlist().then(
                (response) => {
                    this.content = response;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>