import axios from 'axios';

const API_URL_WEATHER = 'https://api.openweathermap.org/data/2.5/weather?';
const API_URL_FORCAST = 'https://api.openweathermap.org/data/2.5/forecast?';
const appid = '187e2014e468c9f2fbf087fc693f8914';
const lat = '51.3417825';
const lon = '12.3936349';
const lang = 'de';
const units = 'metric';
const cnt = '3';
const params = 'appid=' + appid + '&lat=' + lat + '&lon=' + lon + '&lang=' + lang + '&units=' + units;
var date = Date.now() / 1000;
var daynight = 'n';

class OpenWeatherAPI {
    currentWeather() {
        return axios.get(API_URL_WEATHER + params)
            .then(response => {

                if ( date > response.data.sys.sunrise && date < response.data.sys.sunset) {
                    daynight = 'd';
                }

                var result = {
                    id: response.data.weather[0].id,
                    name: response.data.name,
                    description: response.data.weather[0].description,
                    icon: response.data.weather[0].icon,
                    temp: response.data.main.temp.toLocaleString('de-DE'),
                    temp_feels: response.data.main.feels_like.toLocaleString('de-DE'),
                    temp_min: response.data.main.temp_min.toLocaleString('de-DE'),
                    temp_max: response.data.main.temp_max.toLocaleString('de-DE'),
                    wind_speed: response.data.wind.speed.toLocaleString('de-DE'),
                    sunrise: response.data.sys.sunrise,
                    sunset: response.data.sys.sunset,
                    pod: daynight,
                };
                return result;
        });
    }

    forcastWeather() {
        return axios.get(API_URL_FORCAST + params + '&cnt=' + cnt)
            .then(response => {
            var result = [];

            response.data.list.forEach((value, index) => {
                var item = {
                    id: value.weather[0].id,
                    description: value.weather[0].description,
                    icon: value.weather[0].icon,
                    temp: value.main.temp.toLocaleString('de-DE'),
                    wind_speed: value.wind.speed.toLocaleString('de-DE'),
                    //time: new Date(value.dt_txt).toLocaleString('de-DE', {dateStyle: 'long'}),
                    time: 'ab ' + new Date(value.dt_txt).toLocaleTimeString('de-DE', {timeStyle: 'short'}) + ' Uhr',
                    pod: value.sys.pod,
                };

                result.push(item);
            });

        return result;
    });
    }
}

export default new OpenWeatherAPI();