<template>
    <div id="search-modal" class="uk-modal-full uk-modal" uk-modal>
        <div class="uk-modal-dialog uk-flex uk-flex-center uk-flex-middle" uk-height-viewport>
            <button class="uk-modal-close-full" type="button" uk-close></button>
            <form class="uk-search uk-search-large">
                <input class="uk-search-input uk-text-center" type="search" placeholder="Suchen" aria-label="Search" autofocus>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>