<template>

    <div class="uk-container uk-container-expand uk-margin-medium-top">

        <form v-on:submit.prevent="submitToDo">
            <div class="">
                <div class="uk-inline uk-width-expand">
                    <a class="uk-form-icon uk-form-icon-flip has-datalist" href="#" @click="submitToDo" uk-icon="icon: plus-circle"></a>
                    <input list="all-todos" class="uk-input" placeholder="z.b. Einkaufen gehen" type="text" v-model="newToDo" autofocus>
                    <datalist id="all-todos">
                        <option v-for="todo in content.data">{{ todo.todo }}</option>
                    </datalist>
                </div>
            </div>
        </form>

        <hr class="uk-divider-icon uk-margin-medium">

        <div class="uk-card uk-box-shadow-medium">
            <div class="uk-card-header">
                <h3 class="uk-card-title">ToDo`s</h3>
            </div>
            <div class="uk-card-body">
                <ul class="uk-list uk-list-divider uk-margin-remove-bottom" uk-sortable="handle: .uk-sortable-handle">
                    <template v-for="(todo, index) in content.data" :key="todo.id">
                        <li v-if="todo.done === 0">
                            <span uk-icon="more-vertical" class="uk-margin-right uk-sortable-handle"></span>
                            <label><input class="uk-checkbox uk-margin-right" type="checkbox" :value="todo.id" @click="doneToDo"> {{ todo.todo }}</label>
                        </li>
                    </template>
                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import TodoAPI from '@/services/api.todo';

    export default {
        name: 'ToDo',
        data() {
            return {
                content: '',
                newToDo: '',
            };
        },
        methods: {
            doneToDo: function (data) {
                TodoAPI.updateTodo(data.target.value).then(
                    (response) => {
                        console.log(response.data.message);
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            },
            submitToDo: function (data) {
                TodoAPI.postTodo(this.newToDo).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            }
        },
        mounted: function() {
            TodoAPI.getAllTodo().then(
                (response) => {
                    this.content = response.data;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>