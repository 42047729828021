<template>

    <div class="uk-container uk-container-expand uk-margin-medium-top">

        <form v-on:submit.prevent="submitGrocery">
            <div class="">
                <div class="uk-inline uk-width-expand">
                    <a class="uk-form-icon uk-form-icon-flip has-datalist" href="#" @click="submitGrocery" uk-icon="icon: plus-circle"></a>
                    <input list="all-todos" class="uk-input" placeholder="z.b. Tomaten (bio)" type="text" v-model="newGrocery" autofocus>
                    <datalist id="all-todos">
                        <option v-for="grocery in content.data">{{ grocery.title }}</option>
                    </datalist>
                </div>
            </div>
        </form>

        <hr class="uk-divider-icon uk-margin-medium">

        <div class="uk-card uk-box-shadow-medium">
            <div class="uk-card-header">
                <h3 class="uk-card-title">Einkaufszettel <sup><span uk-icon="icon: whatsapp" @click="whatsappShare"></span></sup></h3>
            </div>
            <div class="uk-card-body">
                <ul class="uk-list uk-list-divider uk-margin-remove-bottom">
                    <template v-for="(grocery, index) in content.data" :key="grocery.id">
                        <li v-if="grocery.done === 0">
                            <label><input class="uk-checkbox uk-margin-right" type="checkbox" :value="grocery.id" @click="doneToDo"> {{ grocery.title }}</label>
                        </li>
                    </template>
                </ul>
            </div>
        </div>

    </div>

</template>

<script>
    import GroceryAPI from '@/services/api.grocery';
    import shareWhatsApp from '@/services/share.whatsapp';

    export default {
        name: 'GroceryList',
        data() {
            return {
                content: '',
                newGrocery: '',
            };
        },
        methods: {
            doneGrocery: function (data) {
                GroceryAPI.updateGrocery(data.target.value).then(
                    (response) => {
                        console.log(response.data.message);
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            },
            submitGrocery: function (data) {
                GroceryAPI.postGrocery(this.newGrocery).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            },
            whatsappShare: function (data) {
                var grocery = 'Einkaufszettel:\n\n';

                this.content.data.forEach((item, i) => {
                    grocery += '✓ ' + item.title + '\n';
                });

                shareWhatsApp.shareText(grocery);
            }
        },
        mounted: function() {
            GroceryAPI.getAllGrocery().then(
                (response) => {
                    this.content = response.data;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>