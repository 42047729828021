<template>

    <div class="uk-container uk-container-xsmall uk-margin-medium-top">
        <form v-on:submit.prevent="handleLogin">
            <fieldset class="uk-fieldset">
                <legend class="uk-legend">Anmelden</legend>

                <div class="uk-margin">
                    <div class="uk-inline uk-width-expand">
                        <span class="uk-form-icon" uk-icon="icon: mail"></span>
                        <input class="uk-input" type="email" placeholder="E-Mail" aria-label="Not clickable icon" v-model="form.email" required>
                    </div>
                </div>

                <div class="uk-margin">
                    <div class="uk-inline uk-width-expand">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" placeholder="Passwort" aria-label="Not clickable icon" v-model="form.password" required>
                    </div>
                </div>

                <div class="uk-margin">
                    <button class="uk-button uk-button-default uk-width-1-1">Login</button>
                </div>
            </fieldset>
        </form>
    </div>

    <div v-if="message !== ''" class="uk-alert-danger uk-container-xsmall uk-margin-auto" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>{{ message }}</p>
    </div>

</template>

<script>
    import AuthService from '@/services/auth.user';

    export default {
        name: 'Login',
        data() {
            return {
                loading: false,
                message: '',
                form: {
                    email: '',
                    password: ''
                }
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.status.loggedIn;
            },
        },
        created() {
            if (this.loggedIn) {
                this.$router.push('/');
                window.location.reload();
            }
        },
        methods: {
            handleLogin(user) {
                this.loading = true;
                this.$store.dispatch('login', this.form).then(
                    () => {
                        this.$router.push('/');
                        window.location.reload();
                    },
                    (error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>