<template>
    Calendar
</template>

<script>

    export default {
        name: 'Calendar',
        data() {
            return {
                content: '',
            };
        },
        mounted: function() {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>