<template>
    <div>
        <div class="uk-card uk-box-shadow-medium">
            <div class="uk-card-header">
                <h3 class="uk-card-title uk-margin-remove-bottom">{{ kola.title }}</h3>
            </div>
            <div class="uk-card-body" v-html="kola.content">
            </div>
        </div>
    </div>
</template>

<script>
    import RSStoJsonAPI from '@/services/api.rsstojson';

    export default {
        name: 'Erntezettel',
        data() {
            return {
                kola: {
                    title: '',
                    content: '',
                },
            };
        },
        mounted: function() {
            RSStoJsonAPI.getKoLa().then(
                (response) => {
                    this.kola.title = response.title;
                    var content = response.content.replace(/(<([^>]+)>)/ig, '')
                        .replace(/DE-ÖKO-006/g, '')
                        .replace(/Montag/g, '<h1 class="uk-heading-line"><span class="uk-text-lead">Montag</span></h1>')
                        .replace(/Mittwoch/g, '<h1 class="uk-heading-line"><span class="uk-text-lead">Mittwoch</span></h1>')
                        .replace(/Donnerstag/g, '<h1 class="uk-heading-line"><span class="uk-text-lead">Donnerstag</span></h1>')
                        .replace(/Freitag/g, '<h1 class="uk-heading-line"><span class="uk-text-lead">Freitag</span></h1>')
                        .replace(/M-Kiste/g, ' <sup><small>M-Kiste</small></sup>')
                        .replace(/Frisch von unseren Feldern:/g, '<span class="uk-text-bold uk-text-uppercase">Frisch von unseren Feldern:</span><br>')
                        .replace(/Von anderen Biohöfen:/g, '<br><br><span class="uk-text-bold uk-text-uppercase">Von anderen Biohöfen:</span><br>')
                        .replace(/\)/g, ') ')
                        .split('*');

                    this.kola.content = content[0] + '<br><br><hr class="uk-divider-icon"><span class="uk-text-meta">* Reicht nicht für alle Kisten</span>';
                }
            );
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>