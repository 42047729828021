import axios from 'axios';

const API_URL = 'https://api.whydesign-halle.de/api/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem(
                        'user',
                        JSON.stringify({
                            email: user.email,
                            token: response.data.token,
                            username: response.data.username
                        })
                    );
                }

            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
    }
}

export default new AuthService();