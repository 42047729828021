import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = 'https://api.whydesign-halle.de/api/';

class RetroAPI {
    getWishlist() {
        return axios.get(API_URL + 'retro/wishlist',
                { headers: authHeader() }
            ).then(response => {
                var wishlist = [];

                response.data.data.forEach((game, i) => {
                    switch(game.system) {
                        case 'Microsoft Xbox 360':
                            var sys = '360';
                            break;
                        case 'Nintendo 64':
                            var sys = 'N64';
                            break;
                        case 'Nintendo Entertainment System':
                            var sys = 'NES';
                            break;
                        case 'Nintendo Game Boy':
                            var sys = 'GB';
                            break;
                        case 'Nintendo Game Boy Advance':
                            var sys = 'GBA';
                            break;
                        case 'Nintendo Game Boy Color':
                            var sys = 'GBC';
                            break;
                        case 'Nintendo Super Nintendo Entertainment System':
                            var sys = 'SNES';
                            break;
                        case 'PC (Windows)':
                            var sys = 'PC';
                            break;
                        case 'Sony PlayStation':
                            var sys = 'PSX';
                            break;
                        case 'Sony PlayStation 2':
                            var sys = 'PS2';
                            break;
                        case 'Sony PlayStation 3':
                            var sys = 'PS3';
                            break;
                        case 'Sony PlayStation Portable':
                            var sys = 'PSP';
                            break;
                        default:
                            var sys = '';
                        }

                    var item = {
                        id: game.id,
                        title: game.title,
                        edition: game.edition,
                        system: game.system,
                        region: game.region,
                        sys: sys
                    };

                    wishlist.push(item);
                });

                return wishlist;
            });
    }

    getCollection() {
        return axios.get(API_URL + 'retro/collection',
            { headers: authHeader() }
        ).then(response => {
            var collection = [];

        response.data.data.forEach((game, i) => {
            switch(game.system) {
        case 'Microsoft Xbox 360':
            var sys = '360';
            break;
        case 'Nintendo 64':
            var sys = 'N64';
            break;
        case 'Nintendo Entertainment System':
            var sys = 'NES';
            break;
        case 'Nintendo Game Boy':
            var sys = 'GB';
            break;
        case 'Nintendo Game Boy Advance':
            var sys = 'GBA';
            break;
        case 'Nintendo Game Boy Color':
            var sys = 'GBC';
            break;
        case 'Nintendo Super Nintendo Entertainment System':
            var sys = 'SNES';
            break;
        case 'PC (Windows)':
            var sys = 'PC';
            break;
        case 'Sony PlayStation':
            var sys = 'PSX';
            break;
        case 'Sony PlayStation 2':
            var sys = 'PS2';
            break;
        case 'Sony PlayStation 3':
            var sys = 'PS3';
            break;
        case 'Sony PlayStation Portable':
            var sys = 'PSP';
            break;
        case 'Atari 2600':
            var sys = 'A26';
            break;
        case 'Nintendo Game & Watch':
            var sys = 'NGW';
            break;
        case 'Nintendo DS':
            var sys = 'NDS';
            break;
        case 'Sega Game Gear':
            var sys = 'SGG';
            break;
        default:
            var sys = '';
        }

        var item = {
            id: game.id,
            title: game.title,
            edition: game.edition,
            system: game.system,
            region: game.region,
            zustand: game.zustand,
            bestandteile: game.bestandteile,
            details: game.details,
            ean: game.ean,
            sys: sys
        };

        collection.push(item);
    });

        return collection;
    });
    }

    getCollectionID(id) {
        return axios.get(API_URL + 'retro/collection/' + id,
            { headers: authHeader() }
        );
    }
}

export default new RetroAPI();