<template>
    <div class="uk-container uk-container-expand uk-margin-medium-top">

        <div class="uk-child-width-1-2@l uk-grid-match" uk-grid>

            <div>
                <div class="uk-card uk-box-shadow-medium" uk-filter="target: .game-filter">
                    <div class="uk-card-header">
                        <div>
                            <div class="uk-float-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">Retroplace Wishlist</h3>
                            </div>

                            <div class="uk-float-right">
                                <nav uk-dropnav>
                                    <ul class="uk-subnav">
                                        <li>
                                            <a href="#">Filter <span uk-drop-parent-icon></span></a>
                                            <div class="uk-dropdown">
                                                <ul class="uk-nav uk-dropdown-nav">
                                                    <li class="uk-active" uk-filter-control><a href="#">Alle</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".360"><a href="#">XBOX 360</a></li>
                                                    <li uk-filter-control=".PC"><a href="#">PC</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".NES"><a href="#">NES</a></li>
                                                    <li uk-filter-control=".SNES"><a href="#">SNES</a></li>
                                                    <li uk-filter-control=".N64"><a href="#">N64</a></li>
                                                    <li uk-filter-control=".GB"><a href="#">GB</a></li>
                                                    <li uk-filter-control=".GBA"><a href="#">GBA</a></li>
                                                    <li uk-filter-control=".GBC"><a href="#">GBC</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".PSX"><a href="#">PSX</a></li>
                                                    <li uk-filter-control=".PS2"><a href="#">PS2</a></li>
                                                    <li uk-filter-control=".PS3"><a href="#">PS3</a></li>
                                                    <li uk-filter-control=".PSP"><a href="#">PSP</a></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="uk-card-body">
                        <table class="uk-table uk-table-small uk-table-divider uk-table-hover uk-table-justify uk-table-responsive">
                            <thead>
                            <tr>
                                <th>Titel</th>
                                <th>Edition</th>
                                <th>System</th>
                            </tr>
                            </thead>
                            <tbody class="game-filter">
                            <template v-for="game in wishlist" :key="game">
                                <tr :class="game.sys">
                                    <td>{{ game.title }} <sup class="uk-hidden@m">{{ game.sys }}</sup></td>
                                    <td class="uk-visible@m" v-show="game.edition">{{ game.edition }}</td>
                                    <td class="uk-visible@m" v-show="game.edition == ''">-</td>
                                    <td class="uk-visible@m">{{ game.sys }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div>
                <div class="uk-card uk-box-shadow-medium" uk-filter="target: .game-filter">
                    <div class="uk-card-header">
                        <div>
                            <div class="uk-float-left">
                                <h3 class="uk-card-title uk-margin-remove-bottom">Retroplace Sammlung</h3>
                            </div>

                            <div class="uk-float-right">
                                <nav uk-dropnav>
                                    <ul class="uk-subnav">
                                        <li>
                                            <a href="#">Filter <span uk-drop-parent-icon></span></a>
                                            <div class="uk-dropdown">
                                                <ul class="uk-nav uk-dropdown-nav">
                                                    <li class="uk-active" uk-filter-control><a href="#">Alle</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".A26"><a href="#">A26</a></li>
                                                    <li uk-filter-control=".SGG"><a href="#">SGG</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".360"><a href="#">XBOX 360</a></li>
                                                    <li uk-filter-control=".PC"><a href="#">PC</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".NGW"><a href="#">NGW</a></li>
                                                    <li uk-filter-control=".NES"><a href="#">NES</a></li>
                                                    <li uk-filter-control=".SNES"><a href="#">SNES</a></li>
                                                    <li uk-filter-control=".N64"><a href="#">N64</a></li>
                                                    <li uk-filter-control=".GB"><a href="#">GB</a></li>
                                                    <li uk-filter-control=".GBA"><a href="#">GBA</a></li>
                                                    <li uk-filter-control=".GBC"><a href="#">GBC</a></li>
                                                    <li uk-filter-control=".NDS"><a href="#">NDS</a></li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li uk-filter-control=".PSX"><a href="#">PSX</a></li>
                                                    <li uk-filter-control=".PS2"><a href="#">PS2</a></li>
                                                    <li uk-filter-control=".PS3"><a href="#">PS3</a></li>
                                                    <li uk-filter-control=".PSP"><a href="#">PSP</a></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="uk-card-body">
                        <table class="uk-table uk-table-small uk-table-divider uk-table-hover uk-table-justify uk-table-responsive">
                            <thead>
                            <tr>
                                <th>Titel</th>
                                <th>Edition</th>
                                <th>System</th>
                            </tr>
                            </thead>
                            <tbody class="game-filter">
                            <template v-for="game in collection" :key="game">
                                <tr :class="game.sys" @click="getCollectionInfo" :data-id="game.id">
                                    <td>{{ game.title }} <sup class="uk-hidden@m">{{ game.sys }}</sup></td>
                                    <td class="uk-visible@m" v-show="game.edition">{{ game.edition }}</td>
                                    <td class="uk-visible@m" v-show="game.edition == ''">-</td>
                                    <td class="uk-visible@m">{{ game.sys }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

        <template v-for="game in modal">
        <div id="modal-collection" uk-modal>
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{ game.title }}</h2>
                </div>
                <div class="uk-modal-body" uk-overflow-auto>
                    <dl class="uk-description-list uk-description-list-divider">
                        <dt>System</dt>
                        <dd>{{ game.system }}</dd>

                        <dt>Region</dt>
                        <dd v-if="game.region">{{ game.region }}</dd>
                        <dd v-else>n/a</dd>

                        <dt >Zustand</dt>
                        <dd v-if="game.zustand">{{ game.zustand }}</dd>
                        <dd v-else>n/a</dd>

                        <dt>Edition</dt>
                        <dd v-if="game.edition">{{ game.edition }}</dd>
                        <dd v-else>n/a</dd>

                        <dt>EAN/UPC Code</dt>
                        <dd v-if="game.ean">{{ game.ean }}</dd>
                        <dd v-else>n/a</dd>

                        <dt>Details</dt>
                        <dd v-if="game.details">{{ game.details }}</dd>
                        <dd v-else>n/a</dd>

                        <dt>Bestandteile</dt>
                        <dd v-if="game.bestandteile">{{ game.bestandteile }}</dd>
                        <dd v-else>n/a</dd>
                    </dl>
                </div>
                <div class="uk-modal-footer">
                    <div class="uk-button-group uk-width-expand">
                    <a class="uk-button uk-button-default button-igdb uk-width-expand" :href="'https://www.igdb.com/search?type=1&q=' + igdb" target="_blank">IGDB</a>
                    <a class="uk-button uk-button-default button-lunchbox uk-width-expand" :href="'https://gamesdb.launchbox-app.com/games/results/' + igdb" target="_blank">LaunchBox</a>
                    <a class="uk-button uk-button-default button-retroplace uk-width-expand" :href="'https://www.retroplace.com/de/spiele/marktplatz?search=' + game.ean" target="_blank">Retroplace</a>
                    </div>
                </div>
            </div>
        </div>
        </template>

    </div>
</template>

<script>
    import RetroAPI from '@/services/api.retro';

    export default {
        name: 'Retroplace',
        data() {
            return {
                wishlist: '',
                collection: '',
                modal: '',
                igdb: ''
            };
        },
        methods: {
            getCollectionInfo: function (e) {
                var id = e.currentTarget.getAttribute('data-id')
                RetroAPI.getCollectionID(id).then(
                    (response) => {
                        this.modal = response.data.data;
                        this.igdb = encodeURIComponent(response.data.data[0].title);

                        //var ean = response.data.data[0].ean;
                        //var alias = response.data.data[0].title.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase().replace(/\s/g, '-');
                    }
                ).then(() => {
                    this.$forceUpdate();
                    this.$uikit.modal('#modal-collection').show();
                });
            },
        },
        mounted: function() {
            RetroAPI.getWishlist().then(
                (response) => {
                    this.wishlist = response;
                }
            );
            RetroAPI.getCollection().then(
                (response) => {
                    this.collection = response;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>