import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = 'https://api.whydesign-halle.de/api/';

class GroceryAPI {
    getAllGrocery() {
        return axios.get(API_URL + 'grocery',
                { headers: authHeader() }
            );
    }

    updateGrocery(id) {
        return axios.put(API_URL + 'grocery/' + id,
                { done: 1 },
                { headers: authHeader() }
            );
    }

    postGrocery(grocery) {
        return axios.post(API_URL + 'grocery',
                { title: grocery },
                { headers: authHeader() }
            );
    }
}

export default new GroceryAPI();