import axios from 'axios';

const API_URL = 'https://api.rss2json.com/v1/api.json?rss_url=';

class RSStoJsonAPI {
    getKoLa() {
        return axios.get(API_URL + 'https%3A%2F%2Fkola.abundzu.org%2Fharvest%2Ffeed%2F').then(response => {
                return response.data.items[0];
            });
    }

}

export default new RSStoJsonAPI();