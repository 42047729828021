<template>
    <nav class="uk-navbar-container">
        <div uk-navbar>

            <div class="uk-navbar-left">
                <span uk-navbar-toggle-icon uk-toggle="target: #sidebar-nav"></span>
                <a class="uk-logo uk-navbar-item" href="#">{{ route.meta.title }}</a>
            </div>

            <div class="uk-navbar-right">
                <ul class="uk-navbar-nav">
                    <li v-if="route.matched.some(({ name }) => name === 'Notes')" uk-tooltip="title: Notiz speichern; pos: bottom">
                        <a uk-icon="icon: check"></a>
                    </li>
                    <li v-if="route.matched.some(({ name }) => name === 'Notes')" uk-tooltip="title: Neue Notiz; pos: bottom">
                        <a uk-icon="icon: file-edit"></a>
                    </li>

                    <li v-if="route.matched.some(({ name }) => name === 'Notes')" class="uk-nav-divider"></li>

                    <li uk-tooltip="title: Suchen; pos: bottom">
                        <a uk-icon="icon: search" uk-toggle="target: #search-modal"></a>
                    </li>
                    <li uk-tooltip="title: Vollbild-Ansicht; pos: bottom">
                        <a v-bind:uk-icon="[fullscreenStatus ? 'shrink' : 'expand']" @click="toggleFullscreen"></a>
                    </li>
                    <li uk-tooltip="title: Abmelden; pos: bottom">
                        <a uk-icon="icon: sign-out" @click.prevent="logout"></a>
                    </li>
                </ul>
            </div>

        </div>
    </nav>
</template>

<script>
    import { openFullscreen, closeFullscreen } from '@/utils/fullscreen';
    import { useRoute, useRouter } from 'vue-router'
    import { onMounted } from 'vue'

    //{{ route.path }} //ToDo: Breadcrumb

    export default {
        name: 'App',
        data() {
            return {
                fullscreenStatus: false,
            }
        },
        methods: {
            toggleFullscreen: function (data) {
                this.fullscreenStatus = !this.fullscreenStatus;
                if (this.fullscreenStatus) openFullscreen()
                else closeFullscreen()
            },
            logout: function (data) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
                window.location.reload();
            }
        },
        setup: function () {
            const route = useRoute();
            const router = useRouter();

            onMounted(async () => {
                await router.isReady();
            });

            return { route }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>