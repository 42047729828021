<template>
    <div id="sidebar-nav" uk-offcanvas="mode: push; overlay: true">
        <div class="uk-offcanvas-bar">

            <div class="uk-grid-divider" uk-grid>
                <div class="uk-width-expand">
                    <div class="uk-text-meta uk-text-lighter uk-text-uppercase">Angemeldet als</div>
                    <div class="uk-text-bold">{{ username }}</div>
                </div>
                <div>
                    <a href="" uk-icon="icon: sign-out; ratio: 1.3" @click.prevent="logout" class="uk-icon-button"></a>
                </div>
            </div>

            <hr>

            <div class="uk-margin-top uk-margin-bottom">
                <form class="uk-search uk-search-default uk-width-1-1">
                    <a href="" class="uk-search-icon-flip" uk-search-icon></a>
                    <input class="uk-search-input uk-width-1-1" type="search" placeholder="Suchen" aria-label="Search">
                </form>
            </div>

            <hr>

            <ul class="uk-nav uk-nav-default">
                <li class="uk-nav-header">Menü</li>
                <li :class="{'uk-active': $route.name === 'Dashboard'}">
                    <router-link :to="{ path: '/'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: home"></span>Dashboard
                    </router-link>
                </li>
                <li :class="{'uk-active': $route.name === 'Notes'}">
                    <router-link :to="{ path: '/notes'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: file-edit"></span>Notizen
                    </router-link>
                </li>
                <li :class="{'uk-active': $route.name === 'ToDo'}">
                    <router-link :to="{ path: '/todo'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: list"></span>ToDo Liste
                    </router-link>
                </li>
                <li :class="{'uk-active': $route.name === 'GroceryList'}">
                    <router-link :to="{ path: '/grocery'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: cart"></span>Einkaufszettel
                    </router-link>
                </li>
                <li :class="{'uk-active': $route.name === 'Retroplace'}">
                    <router-link :to="{ path: '/retroplace'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: play-circle"></span>Retroplace Liste
                    </router-link>
                </li>
                <li :class="{'uk-active': $route.name === 'Calendar'}">
                    <router-link :to="{ path: '/calendar'}" @click.native="menuClicked">
                        <span class="uk-margin-small-right" uk-icon="icon: calendar"></span>Termine
                    </router-link>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                username: ''
            }
        },
        methods: {
            logout: function (data) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
                window.location.reload();
            },
            menuClicked: function (data) {
                this.$uikit.offcanvas('#sidebar-nav').hide();
            }
        },
        computed: {
            loggedIn() {
                return this.$store.state.user.username;
            }
        },
        created() {
            if (!!localStorage.hasOwnProperty('user')) {
                this.username = this.loggedIn;
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>