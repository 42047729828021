<template>
    <div>
        <div class="uk-card uk-box-shadow-medium card-hasfooter">
            <div class="uk-card-header">
                <h3 class="uk-card-title">Einkaufszettel <sup><span uk-icon="icon: whatsapp" @click="whatsappShare"></span></sup></h3>
            </div>
            <div class="uk-card-body">
                <ul class="uk-list uk-list-divider uk-margin-remove-bottom">
                    <template v-for="grocery in content.data" :key="grocery">
                        <li v-if="grocery.done === 0">
                            <label><input class="uk-checkbox uk-margin-right" type="checkbox" :value="grocery.id" @click="doneGrocery"> {{ grocery.title }}</label>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="uk-card-footer uk-position-bottom">
                <a href="#modal-grocery" class="uk-button uk-button-text" uk-toggle><span uk-icon="icon: plus-circle"></span> Hinzufügen</a>
            </div>
        </div>

        <div id="modal-grocery" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">

                <button class="uk-modal-close-default" type="button" uk-close></button>

                <div class="uk-modal-body" uk-overflow-auto>
                    <form v-on:submit.prevent="submitGrocery">
                        <div class="">
                            <div class="uk-inline uk-width-expand">
                                <a class="uk-form-icon uk-form-icon-flip has-datalist" href="#" @click="submitGrocery" uk-icon="icon: plus-circle"></a>
                                <input list="all-grocerys" class="uk-input" placeholder="z.b. Tomaten (bio)" type="text" v-model="newGrocery" autofocus>
                                <datalist id="all-grocerys">
                                    <option v-for="grocery in content.data">{{ grocery.title }}</option>
                                </datalist>
                            </div>
                        </div>
                    </form>

                    <div v-if="newGrocerys != ''" class="uk-margin-top">
                        <hr class="uk-divider-icon">

                        <ul class="uk-list uk-list-divider uk-margin-remove-bottom">
                            <li v-for="grocery in newGrocerys">{{ grocery }}</li>
                        </ul>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-modal-close" type="button" @click="closeModal">Fertig</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GroceryAPI from '@/services/api.grocery';
    import shareWhatsApp from '@/services/share.whatsapp';

    export default {
        name: 'GroceryCard',
        data() {
            return {
                content: '',
                newGrocerys: []
            };
        },
        methods: {
            doneGrocery: function (data) {
                GroceryAPI.updateGrocery(data.target.value).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            window.location.reload();
                        }
                    }
                );
            },
            submitGrocery: function (data) {
                GroceryAPI.postGrocery(this.newGrocery).then(
                    (response) => {
                        if (response.data.message === 'succeed') {
                            this.newGrocerys.push(this.newGrocery);
                        }
                    }
                );
            },
            closeModal: function (data) {
                window.location.reload();
            },
            whatsappShare: function (data) {
                var grocery = 'Einkaufszettel:\n\n';

                this.content.data.forEach((item, i) => {
                    grocery += '✓ ' + item.title + '\n';
                });

                shareWhatsApp.shareText(grocery);
            }
        },
        mounted: function() {
            GroceryAPI.getAllGrocery().then(
                (response) => {
                    this.content = response.data;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>