<template>
    <div class="uk-container uk-container-expand uk-margin-medium-top dashboard">

        <div class="uk-child-width-1-3@m uk-child-width-1-3@l uk-grid-match" uk-grid>
            <ColorCard />
        </div>

        <div class="uk-child-width-1-3@m uk-grid-match" uk-grid>
            <TodoCard />
            <WeatherCard />
            <GroceryCard />
        </div>

        <div class="uk-child-width-1-2@m uk-grid-match" uk-grid>
            <RetroCard />
            <KolaCard />
        </div>

    </div>
</template>

<script>
    import ColorCard from '@/components/colorcard/ColorCard.vue';
    import TodoCard from '@/components/todocard/TodoCard.vue';
    import WeatherCard from '@/components/weathercard/WeatherCard.vue';
    import GroceryCard from '@/components/grocerycard/GroceryCard.vue';
    import RetroCard from '@/components/retrocard/RetroCard.vue';
    import KolaCard from '@/components/kolacard/KolaCard.vue';

    export default {
        name: 'Dashboard',
        components: {
            ColorCard,
            TodoCard,
            WeatherCard,
            GroceryCard,
            RetroCard,
            KolaCard,
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .dashboard {
        .uk-card {
            max-height: 465px;

            &-body {
                max-height: 243px;
            }
        }
    }
</style>