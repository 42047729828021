<template>
    <div id="markdown-editor">

        <div class="uk-grid-collapse uk-grid-divider uk-child-width-expand@s" uk-grid>
            <div>
                <textarea :value="input" @input="update" class="uk-height-large"></textarea>
            </div>
            <div>
                <div class="preview uk-height-large" v-html="compiledMarkdown"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import { marked } from 'marked';
    import _ from 'lodash';

    export default {
        name: 'Notes',
        data() {
            return {
                input: '# hello'
            }
        },
        computed: {
            compiledMarkdown() {
                return marked(this.input, { sanitize: true });
            }
        },
        methods: {
            update: _.debounce(function(e) {
                this.input = e.target.value;
                this.$forceUpdate();
            }, 300)
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    html, body {
        height: 100%;
    }
</style>