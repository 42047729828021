<template>

    <div>
        <div class="uk-card uk-box-shadow-medium uk-overflow-hidden weather-card">
            <div class="uk-card-header uk-background-cover uk-panel uk-light uk-padding-remove" uk-parallax="bgy: -200">

                <div class="uk-grid-collapse uk-flex-middle uk-overlay-primary uk-margin uk-height-small" v-if="weather !== null" uk-grid>
                    <div class="uk-width-auto">
                        <i :class="'owf current uk-margin-right uk-margin-left owf-' + weather.id + '-' + weather.pod"></i>
                    </div>
                    <div class="uk-width-expand">
                        <h3 class="uk-card-title uk-margin-remove-bottom uk-light">{{ weather.temp }} C° • {{ weather.wind_speed }} km/h</h3>
                        <p class="uk-text-meta uk-margin-remove uk-light"><small>{{ weather.name }}, {{ date }} • {{ weather.description }}</small></p>
                        <p class="uk-text-meta uk-margin-remove-top uk-light"><small>{{ weather.temp_min }} C° / {{ weather.temp_max }} C° • gefühlt {{ weather.temp_feels }} C°</small></p>
                    </div>
                </div>

            </div>

            <div class="uk-card-body">

                <ul class="uk-list uk-list-divider uk-list-large" v-if="forcasts !== null">
                    <template v-for="forcast in forcasts" :key="forcast">
                        <li class="uk-grid-collapse" uk-grid>
                            <div class="uk-width-auto">
                                <i :class="'owf uk-margin-right owf-' + forcast.id + '-' + forcast.pod"></i>
                            </div>
                            <div class="uk-width-expand uk-text-small">
                                <p class="uk-text-bold uk-margin-remove">{{ forcast.temp }} C° • {{ forcast.wind_speed }} km/h</p>
                                <small>{{ forcast.time }} • {{ forcast.description }}</small>
                            </div>
                        </li>
                    </template>
                </ul>

            </div>
        </div>
    </div>

</template>

<script>
    import OpenWeatherAPI from '@/services/api.weather';

    export default {
        name: 'WeatherCard',
        data() {
            return {
                weather: null,
                forcasts: null,
                date: new Date().toLocaleString('de-DE', {dateStyle: 'long'})
            };
        },
        mounted: function() {
            OpenWeatherAPI.currentWeather().then(
                (response) => {
                    this.weather = response;
                }
            );
            OpenWeatherAPI.forcastWeather().then(
                (response) => {
                    this.forcasts = response;
                }
            );
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .weather-card {
        .uk-background-cover {
            background-image: url(@/assets/img/weather-header.jpg);
        }

        .uk-light {
            color: #fff;
        }

        .owf {
            color: #333333;
            font-size: 45px;

            &.current {
                font-size: 75px;
                color: #fff;
            }
        }

        .uk-card-body {
            .owf {
                width: 44px;
            }
        }
    }
</style>